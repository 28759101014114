import { Injectable, isDevMode } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { BaseUserModel, DeviceCodeItem, NewUserAccountRequest } from '../models/user';
import { SharedFunctionService } from './shared.function.service';
import { TrackingData } from '../models/tracking';
import { PaUserToken } from '../models/access.token.models/user.token';
import { ApiService } from './api.service';
import { PasswordReSetRequest } from '../models/http.request.models/pasword.reset.request';
import { DetailResponseMessage, GeneralResponseMessage } from '../models/messages/general.response.message';
import { SimpleResponse } from '../models/http.response.models/simple.response';
import { UserDetail } from '../models/user.detail';
import { UserExistingLicense } from '../models/qm.license.models/qm.existing.license';
import { QmFspDetail } from '../models/qm.fsp.detail';
import { QmLicense } from '../models/qm.license.models/qm.license';
import { MfaMethod } from '../models/access.token.models/mfa-method.model';
import { MfaVCode } from '../models/access.token.models/mfa-vcode.model';


@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private testSiteCode: string;
  private userLogin: BaseUserModel;
  private userDetail: UserDetail;



  constructor(
    private apiAddressService: ApiAddressService,
    private sharedFunction: SharedFunctionService,
    private apiService: ApiService

  ) {
    this.userDetail = new UserDetail();

    if (isDevMode()) {
      this.testSiteCode = 'QA-';
    } else {
      this.testSiteCode = '';
    }
  }



  doGoogleTracking(category: string, action: string, label: string) {

    let userToken = this.apiService.getUserToken();
    let userId = userToken !== null ? userToken.UserId : 0;

    let trackingData = new TrackingData();
    trackingData.Category = category;
    trackingData.Action = action;
    trackingData.Label = label;
    trackingData.UserId = userId;

    this.doUserActionTracking(trackingData);


    (<any>window).gtag('event', action, {
      'event_category': 'UIV2-' + category,
      'event_label': label + ' U-' + userId,
      'value': userId
    });

 

    (<any>window).appInsights.trackEvent(category, {
      'Action': action,
      'Label': label,
      'UserId': userId
    });

    (<any>window).googleRecaptchaApiCaller('UIV2-' + category,userId, (token, userId) => {
      this.sharedFunction.processingGoogleReCaptchaToken(token, userId);
    });
  }


  doGoogleTrackingWithoutUId(category: string, action: string, label: string) {

    let userToken = this.apiService.getUserToken();
    let userId = userToken !== null ? userToken.UserId : 0;

    let trackingData = new TrackingData();
    trackingData.Category = category;
    trackingData.Action = action;
    trackingData.Label = label;
    trackingData.UserId = userId;

    this.doUserActionTracking(trackingData);


    (<any>window).gtag('event', action, {
      'event_category': 'UIV2-' + category,
      'event_label': label + ' U-' + userId,
      'value': userId
    });


    (<any>window).googleRecaptchaApiCaller('UIV2-' + category,userId, (token, userId) => {
      this.sharedFunction.processingGoogleReCaptchaToken(token, userId);
    });


    (<any>window).appInsights.trackEvent(category, {
      'Action': action,
      'Label': label,
      'UserId': userId
    });
  }

  trackScreenView(screenName: string) {

    let userToken = this.apiService.getUserToken();
    let userId = userToken !== null ? userToken.UserId : 0;

    (<any>window).gtag('event', 'screen_view', {
      'app_name': 'Quotemosnter',
      'screen_name': screenName
    });



    (<any>window).appInsights.trackEvent('screen_view', {
      'Action': screenName,
      'Label': screenName,
      'UserId': userId
    });
  }



  getAccessToken(userModel: BaseUserModel, callback: (response: PaUserToken) => void) {
      // do user login
      this.apiService.callApiWithoutAuth<PaUserToken>(
          userModel,
          this.apiAddressService.getAccessTokenUrl(),
          (response) => {
              callback(response);
          }
      );
  }

  getAllMfaMethods(callback: (data: MfaMethod[]) => void) {
    // get user MFA methods without token
    this.apiService.callApiWithoutAuth<MfaMethod[]>(
        '',
        this.apiAddressService.getAvailableMfaMethodsUrl(this.getCurrentLoginUserId(), this.getDeviceCode()),
        (response) => {
            callback(response);
        }
    );
  }
    
  addMfaMethod(methodCode: string, callback: (response: GeneralResponseMessage) => void) {
    let newMfaVCode = new MfaVCode();
    newMfaVCode.UserId = this.getCurrentLoginUserId();
    newMfaVCode.DeviceCode = this.getDeviceCode();
    newMfaVCode.MethodCode = methodCode;

    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
        newMfaVCode,
        this.apiAddressService.addMfaMethodUrl(),
        (response) => {
            callback(response);
        }
    );
  }


  doCreateUser(newUserRequest: NewUserAccountRequest, callback: (response: UserDetail) => void) {
    this.apiService.callApiWithoutAuth<UserDetail>(
      newUserRequest, this.apiAddressService.getCreateUserUrl(), (response) => { callback(response) });
  }

  doRequestReSetPasswordLink(request: SimpleResponse, callback: (response: DetailResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<DetailResponseMessage>(
      request, this.apiAddressService.getRequestReSetPasswordLinkUrl(), (response) => { callback(response) });
  }


  doReSetPasswordByKey(passwordRequest: PasswordReSetRequest, callback: (response: DetailResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<DetailResponseMessage>(
      passwordRequest, this.apiAddressService.getReSetPasswordByKeyUrl(), (response) => { callback(response) });
  }

  doUpdatePaPassword(passwordRequest: PasswordReSetRequest, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApi<GeneralResponseMessage>(
      passwordRequest, this.apiAddressService.getUpdatePaPasswordUrl(), (response) => { callback(response) });
  }
  
  doReSetPaPassword(request: SimpleResponse, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
      request, this.apiAddressService.getResetPaPasswordUrl(), (response) => { callback(response) });
  }
  
  doReSetPaPasswordByKey(passwordRequest: PasswordReSetRequest, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
      passwordRequest, this.apiAddressService.getReSetPaPasswordByKeyUrl(), (response) => { callback(response) });
  }

  doUserActionTracking(trackingData: TrackingData) {
    this.apiService.callApiWithoutAuth<string>(trackingData, this.apiAddressService.getQMTrackingUrl(), (response) => { });
  }


 doResendDeviceVerificationCode(userModel: BaseUserModel, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
        userModel,
        this.apiAddressService.getReSendUserDeviceVerificationCodeUrl(),
        (response) => {
            callback(response);
        }
    );
  }

  doCheckDeviceVerificationCode(userModel: BaseUserModel, callback: (response: PaUserToken) => void) {
    this.apiService.callApiWithoutAuth<PaUserToken>(
        userModel,
        this.apiAddressService.checkUserDeviceVerificationCodeUrl(),
        (response) => {
            callback(response);
        }
    );
  }
    
  doCheckMfaVerificationCode(mfaVCode: MfaVCode, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
        mfaVCode,
        this.apiAddressService.checkMFAVerificationCodeUrl(),
        (response) => {
            callback(response);
        }
    );
  }
    
  getRefreshAccessToken(data: any,callback: (response: PaUserToken) => void) {
    this.apiService.callApiWithoutAuth<any>(
        data,
        this.apiAddressService.getRefreshAccessTokenHttpRequestUrl(),
        (response) => {
            callback(response);
        }
    );
  }
    
  doResendMfaVerificationCode(data: MfaVCode , callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
        data,
        this.apiAddressService.resendMfaVerificationCodeUrl(),
        (response) => {
            callback(response);
        }
    );
  }

  doCheckFspDetail(fsp: string, callback: (response: QmFspDetail) => void) {
    this.apiService.callApiWithoutAuth<QmFspDetail>(
      '', this.apiAddressService.getCheckFspDetailUrl(fsp),
      (response) => { callback(response); }
    );
  }


  doCheckEmail(email: string, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
      '', this.apiAddressService.getCheckEmailUrl(email),
      (response) => { callback(response); }
    );
  }

  // get device code from qm_device_code
  getDeviceCodeFromQmDeviceCode(): string {
    return this.apiService.getDeviceCodeFromQmDeviceCode();
  }

  // remove device code from qm_device_code
  removeDeviceCode():void {
    this.apiService.removeDeviceCode();
  }

  // get device code from qm_device_code_items
  getDeviceCode(userName: string = this.getCurrentLoginUserName()): string {
    return this.apiService.getDeviceCode(userName);
  }

  getDeviceCodeItems(): DeviceCodeItem[] {
    return this.apiService.getDeviceCodeItems();
  }

  setDeviceCodeItems(deviceCodeItemsArray: DeviceCodeItem[]) {
    this.apiService.setDeviceCodeItems(deviceCodeItemsArray);
  }

  //update or add device code item
  updateDeviceCodeItems(deviceCodeItemsArray: DeviceCodeItem[], userName: string, userId: number, deviceCode: string): void {
    // check same userName or userId
    let hasExistingDeviceCodeItem: boolean = false;
    let filterResult: DeviceCodeItem[] = [];

    filterResult = deviceCodeItemsArray.filter((item) => item.UserName === userName && item.UserId === userId);

    if (filterResult.length > 0) {
        hasExistingDeviceCodeItem = true;
    }

    if (hasExistingDeviceCodeItem) {
        // update existing device code item
        deviceCodeItemsArray.forEach((item) => {
            if (item.UserName === userName || item.UserId === userId) {
                item.UserName = userName;
                item.UserId = userId;
                item.DeviceCode = deviceCode;
            }
        });
    } else {
        // add new device code item
        let newUserDeviceCodeItem = new DeviceCodeItem();
        newUserDeviceCodeItem.UserName = userName;
        newUserDeviceCodeItem.UserId = userId;
        newUserDeviceCodeItem.DeviceCode = deviceCode;
        deviceCodeItemsArray.push(newUserDeviceCodeItem);
    }

    this.setDeviceCodeItems(deviceCodeItemsArray);
  }
    

  setLoginMfaInfo(mfaInfo: GeneralResponseMessage, userId: number) {
    this.apiService.setLoginMfaInfo(mfaInfo, userId);
  }

  setCurrentLoginUserName(userName:string){
    window.sessionStorage.setItem('qmUserEmail', userName);
  }

  getCurrentLoginUserName(): string {
    return window.sessionStorage.getItem('qmUserEmail')
  }

  setCurrentLoginUserId(userId: number) {
    this.apiService.setCurrentLoginUserId(userId);
  }
    


  getCurrentLoginUserId(): number {
    return this.apiService.getCurrentLoginUserId();
  }

  getLoginMfaInfo(userId: number): GeneralResponseMessage {
    return this.apiService.getLoginMfaInfo(userId);
  }

  clearLoginMfaInfo() {
    this.apiService.clearLoginMfaInfo();
  }

  refreshAccessTokenAndReloadLicense(callback: () => void):void {
    this.apiService.refreshAccessTokenAndReloadLicense(callback);
  }

  saveUserToken(userToken: PaUserToken) {
    this.apiService.saveUserToken(userToken);
  }
    
  getUserToken():PaUserToken {
    return this.apiService.getUserToken();
  }


  saveUserInfo(userToken: PaUserToken) {
    // set user login email
    this.userLogin.UserName = userToken.UserName;
    this.setCurrentLoginUserName(userToken.UserName);
    // set login user info
    let userEmail: string = '';
    if (userToken.Role === 'QM-PA-User') {
      userEmail = userToken.UserName + ', Main Account is: ' + userToken.MasterEmail;
      this.userLogin.MasterEmail = userToken.MasterEmail;
      window.sessionStorage.setItem('qmMasterEmail', userToken.MasterEmail);
    } else {
      userEmail = userToken.UserName;
    }

    if (userEmail) {
      window.sessionStorage.setItem('qmUserInfo', userEmail);
      //document.getElementById('loginedAs').innerHTML = 'Logged in as : ' + userEmail;


      // add fresh chat info
      // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

      // To set unique user id in your system when it is available
      if ((<any>window).fcWidget) {
        (<any>window).fcWidget.setExternalId(userToken.UserId);

        // To set user name
        (<any>window).fcWidget.user.setFirstName(userEmail);

        // To set user email
        (<any>window).fcWidget.user.setEmail(userEmail);
      }
    }
  }



  // init user refresh token and set user login status
  initUserToken() {

    this.userLogin = new BaseUserModel();

    let userToken = this.apiService.getUserToken();

    if (userToken && userToken.RefreshToken && userToken.RefreshToken.Token) {
      if (userToken.Role === 'QM-PA-User') {
        this.userLogin.UserName = this.getCurrentLoginUserName();
        this.userLogin.MasterEmail = window.sessionStorage.getItem('qmMasterEmail');
      } else {
        this.userLogin.UserName = this.getCurrentLoginUserName();
        this.userLogin.MasterEmail = '';
      }
    } else if (this.getCurrentLoginUserName()) {
        this.userLogin.UserName = this.getCurrentLoginUserName();
        if (window.sessionStorage.getItem('qmMasterEmail')) {
            this.userLogin.MasterEmail = window.sessionStorage.getItem('qmMasterEmail');
        } else {
            this.userLogin.MasterEmail = '';
        }
    }


    // set login user info
    let userEmail: string = '';
    userEmail = window.sessionStorage.getItem('qmUserInfo');

    if (userEmail) {
      //document.getElementById('loginedAs').innerHTML = 'Logged in as : ' + userEmail;

      // add fresh chat info
      // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

      // To set unique user id in your system when it is available
      if ((<any>window).fcWidget) {
        (<any>window).fcWidget.setExternalId(userToken.UserId);

        // To set user name
        (<any>window).fcWidget.user.setFirstName(userEmail);

        // To set user email
        (<any>window).fcWidget.user.setEmail(userEmail);
      }
    }

    //TODO: need re-load license type when refresh page.



  }


  getMainUserName(): string {
    if (this.userLogin.MasterEmail) {
      return this.userLogin.MasterEmail;
    } else {
      return this.userLogin.UserName;
    }
  }

  clearData() {
    // clear all session storage data
    window.sessionStorage.clear();
    // clear all login data
    this.userLogin = new BaseUserModel();
    this.apiService.clearUserToken();
  }

  isLogin(): boolean {
    // check user detail
    if (this.userLogin && this.userLogin.UserName) {
      // SHOULD check MFA status
      const mfaInfo = this.getLoginMfaInfo(this.getCurrentLoginUserId());

      if (mfaInfo.Message === '' && mfaInfo.MessageCode === 0) {
        return true;
      }
    }

    return false;
  }

  logout() {
    // // clear data
    // // 1. clear all session data
    window.sessionStorage.clear();
    // // all login data
    this.userLogin = new BaseUserModel();
    this.apiService.clearUserToken();
    // // this.router.navigate(['/home']);
    window.location.assign(window.location.origin);
  }

  checkLoginStatus() {
    // check user login status, if not login then redirect to login page.
    if (!this.isLogin()) {
      // back to login page
      this.logout();
    }
  }


  saveUserEmailCookie(userEmail: string) {
    window.localStorage.setItem('qmUserEmail', userEmail);
  }



  getSavedUserEmail() {
    let userEmail: string = window.localStorage.getItem('qmUserEmail');
    if (userEmail === null || userEmail === '' || userEmail === undefined) {
      userEmail = '';
    }

    return userEmail;
  }


  saveUserDeviceCodeToLocal(deviceCode: string, userName: string) {
    window.localStorage.setItem(this.testSiteCode + 'qmUserDeviceCode-' + userName.toLowerCase(), deviceCode);
  }


  getSavedUserDeviceCode(userName: string) {
    let userDeviceCode: string = window.localStorage.getItem(this.testSiteCode + 'qmUserDeviceCode-' + userName.toLowerCase());

    if (userDeviceCode === null || userDeviceCode === '' || userDeviceCode === undefined) {
      userDeviceCode = window.localStorage.getItem('qmUserDeviceCode');
    }


    if (userDeviceCode === null || userDeviceCode === '' || userDeviceCode === undefined) {
      userDeviceCode = null;
    } else {
      this.saveUserDeviceCodeToLocal(userDeviceCode, userName);
    }

    return userDeviceCode;
  }


  isPaLogin(): boolean {
      this.userLogin.MasterEmail = window.sessionStorage.getItem('qmMasterEmail')
      return this.userLogin.MasterEmail ? true : false;
  }


  isThirdCompanyLoginUser(): boolean {

    return this.apiService.getUserToken().Role === 'QM-3-User';
  }

  // accessId Value
  // 1. free USER_ROLE_FREE: number = 0
  // 2. QM USER_ROLE_QM: number = 1
  // 3. AM USER_ROLE_AM: number = 2
  // 4. PA USER_ROLE_PA: number = -1;

  checkUserAccessRole(minAccessLevel: number): boolean {
    let pass: boolean = false;
    let userExistingLicense: UserExistingLicense = this.apiService.getUserLicense();

    if (userExistingLicense && userExistingLicense.IsActive && userExistingLicense.QmLicense) {
      if (userExistingLicense.QmLicense.AccessLevel >= minAccessLevel) {
        pass = true;
      }
    }
    return pass;
  }

  getUserLicense():QmLicense{
    let qmLicense = new QmLicense();
    qmLicense.Name = "FREE";

    if(this.isLogin()){
      let userExistingLicense: UserExistingLicense = this.apiService.getUserLicense();
      if (userExistingLicense && userExistingLicense.IsActive && userExistingLicense.QmLicense){
        qmLicense = userExistingLicense.QmLicense;
      }
    }
    
    return qmLicense;
  }


}
