import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForUIPasswordValidation, PasswordValidation } from '../../tools/form.validation';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { ActivatedRoute } from '@angular/router';
import { PasswordReSetRequest } from 'src/app/models/http.request.models/pasword.reset.request';
import { BaseUserModel } from 'src/app/models/user';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { DetailResponseMessage } from 'src/app/models/messages/general.response.message';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends ComponentBaseClass implements OnInit {

  userModel: BaseUserModel = new BaseUserModel();
  resetPasswordForm: FormGroup;
  messageLogin: string;
  key1: string = '';
  key2: string = '';
  showLoginBtn: boolean = false;
  hidePassword:boolean = true;
  hideConfirmPassword:boolean = true;

  isInitialState: boolean = true;
  passwordSecurity: any
  isSuccess: boolean = false;
  isPaLogin: boolean = false;

  // Use "constructor"s only for dependency injection
  constructor(
    public loginService: LoginService,
    private resetPasswordFormBuilder: FormBuilder,
    private route: ActivatedRoute,
    public sharedFunction: SharedFunctionService,
  ) {
    super();
  }

  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
    this.showLoginBtn = false;
    // get key1 & key2 from url
    this.key1 = this.route.snapshot.queryParamMap.get('id');
    this.key2 = this.route.snapshot.queryParamMap.get('key');

    if (!this.key1 || !this.key2) {
      this.messageLogin = this.sharedFunction.getUiMessageByCode('Share-ERROR-InvalidatedLink');
    }
      
    this.buildForm();

    this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(null).passwordSecurity;
  }


  buildForm(): void {
    this.userModel = new BaseUserModel();


    this.resetPasswordForm = this.resetPasswordFormBuilder.group({
      'UserName': [null, Validators.compose([Validators.required, Validators.email])],
      'Password': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(32),
      Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern"))])],
      'ConfirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(32),
      Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern"))])],
      'validate': ''
    }, {
      validators: [PasswordValidation.MatchPassword]
    });
  }

  doResetPassword(user): void {
    this.showDinoLoading();
    this.showLoginBtn = false;
    this.isSuccess = false;
    let passwordResetRequest = new PasswordReSetRequest(user.UserName, user.Password, this.key1, this.key2);

    // reset user password
    this.loginService.doReSetPasswordByKey(passwordResetRequest, (response) => {
        this.doResetPasswordCallBack(response);
    });
    
  }

  onPasswordValueChanges(password:string):void{
    this.isInitialState = false;
    this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(password).passwordSecurity;
  }
  
  doResetPasswordCallBack(response: DetailResponseMessage) {
      if (response.Result && response.Result.MessageCode === 200) {
          this.showLoginBtn = true;
          this.isSuccess = true;
          this.messageLogin = this.sharedFunction.getUiMessageByCode('ResetPasswordComponent-SUCCESS-PasswordUpdated');
      } else {
          this.messageLogin = response.Result.Message;
      }
      this.closeDinoLoading();
  }

}
