import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { LoginService } from '../service/login.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private loginService: LoginService) {}
    canActivate(): boolean | UrlTree {
        this.loginService.initUserToken();
        if (this.loginService.isLogin()) {
            return true;
        } else {
            this.router.navigate(['login']);
        }
    }
}
