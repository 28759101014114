import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogComponent } from './confirm-message-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ConfirmMessageDialogService {

    constructor(private dialog: MatDialog) {

    }


    confirm(messageSetting: GeneralMessageDialogSetting, width: string = '540px'): Observable<any> {

        const dialogRef = this.dialog.open(ConfirmMessageDialogComponent, {
            data: messageSetting,
            disableClose: true,
            width: width,
            autoFocus: false,
            restoreFocus: false,
        });


        return dialogRef.afterClosed();
    }
}

