import { GroupMember } from './group-member.model';

export class Group {
    Id: number;
    GroupName: string;
    Description: string;
}

export class UIGroup extends Group {
    Members: GroupMember[];
}
