import { Router } from '@angular/router';
import { ConfirmMessageDialogService } from '../components/shared/confirm-message-dialog.service';
import { AccessRole } from '../models/access.token.models/access.role';
import { GeneralMessageDialogSetting } from '../models/general-message-dialog-setting';
import { LoginService } from './login.service';

export class ComponentBaseClass {


    public accessRole: AccessRole = new AccessRole();
    
    showDinoLoading() {
        document.getElementById('dino-loading').setAttribute('style', 'display:block');
    }

    closeDinoLoading() {
        window.setTimeout(function () {
            document.getElementById('dino-loading').setAttribute('style', 'display:none');
        }, 200);
    }

    checkAccessPermission(
        minAccessRole: number, 
        loginService: LoginService, 
        router: Router, 
        confirmDialog: ConfirmMessageDialogService, 
        message: string
        ) {
        if (!loginService.checkUserAccessRole(minAccessRole)) {
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
            messageSetting.Title = 'Alert';
            messageSetting.NeedYesBtn = false;
            messageSetting.Message = message;
            confirmDialog.confirm(messageSetting).subscribe((response) => {
                router.navigate(['/research/subscription/plan']);
            })
        }
    }
}





